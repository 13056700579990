import React, {Component} from 'react';
import "./ScreenTitle.css"
import classNames from "classnames/bind"

class ScreenTitle extends Component {
    render() {
        return (
            <div
                className={classNames("title", this.props.className)}
                id={this.props.id}
            >
                {this.props.children}
            </div>
        );
    }
}

export default ScreenTitle;
