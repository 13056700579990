import React, { Component } from 'react'
import PropTypes from "prop-types"

import "./NavItem.css"

class NavItem extends Component {
    render() {
        return (
            <li className="js-nav-item">
                {this.props.children}
            </li>
        )
    }
}

NavItem.propTypes = {
    children: PropTypes.node,
}

export default NavItem