import React, {Component} from 'react';

import "./Spinner.css"

class Spinner extends Component {
    render() {
        return (
            <div className="lds-heart">
                <div/>
            </div>
        );
    }
}

export default Spinner;
