import "./App.css"

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    Route
} from "react-router-dom"
import Couple from './screens/couple/Couple'
import Program from './screens/program/Program'
import Gifts from './screens/gifts/Gifts'
import Rsvp from './screens/rsvp/Rsvp'
import Contact from './screens/contact/Contact'
import Form from "./screens/form/Form";

import Nav from "./components/nav/Nav";
import Content from "./components/content/Content";


class App extends Component {

    render() {
        return (
            <div className={"js-body"}>
                <Nav/>
                <Content>
                    <Route path="/contact"><Contact/></Route>
                    <Route path="/cadeaus"><Gifts/></Route>
                    <Route path="/de-grote-dag"><Program/></Route>
                    <Route path="/jimme-en-santoshi"><Couple/></Route>
                    <Route path="/reponse-sil-vous-plait"><Rsvp/></Route>
                    <Route path="/rsvp"><Form/></Route>
                    <Route path="/"><Rsvp/></Route>
                </Content>
            </div>
        )
    }
}

App.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

export default App
