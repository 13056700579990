import React, {Component} from 'react';
import InnerScreen from "../../components/screen/InnerScreen";
import ScreenTitle from "../../components/screenTitle/ScreenTitle";
import Screen from "../../components/screen/Screen";
import Footer from "../../components/screen/Footer";

import FormContextProvider from "../../context/FormContext";
import RsvpForm from "../../components/RSVPForm/RSVPForm";

import "./Form.css"

class Form extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <Screen className="form-screen">
                <InnerScreen>
                    <ScreenTitle>
                        <h1>RSVP</h1>
                    </ScreenTitle>
                    <div className="form-info">
                        <p>
                            Juich niet te vroeg. We duimen dat iedereen kan komen die we hebben
                            uitgenodigd. Helaas zijn we afhankelijk van de dan geldende
                            coronamaatregelen. We willen je daarom alvast vragen, als het kan, zonder
                            kinderen te komen. Daarnaast laten we je uiterlijk 1 juni weten of je echt
                            kunt komen.
                        </p>
                        <p>
                            Geef hieronder aan ons door of je erbij bent.
                        </p>
                    </div>
                    <FormContextProvider>
                        <RsvpForm/>
                    </FormContextProvider>
                </InnerScreen>
                <Footer/>
            </Screen>
        )
    }
}

export default Form
