import React, {Component} from 'react'
import PropTypes from 'prop-types'


import "./Gift.css"
import RatioImage from "../ratioImage/RatioImage";
import {API_URL} from "../../Constants";

class Gift extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            name: "",
            giftAmount: props.price
        }

        this.onToggleOpen = this.onToggleOpen.bind(this)
        this.onNumberChange = this.onNumberChange.bind(this)
        this.onNameChange = this.onNameChange.bind(this)
        this.onSend = this.onSend.bind(this)
    }

    onToggleOpen() {
        this.setState(({open}) => ({open: !open}))
    }

    postGift() {
        const data = JSON.stringify({
            "present_uuid": this.props.uuid,
            name: this.state.name,
            amount: this.state.giftAmount.replace(",", "."),
        })

        return fetch(`${API_URL}/gifts`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        })
            .then(() => this.props.onSend())
    }

    onSend(){
        this.postGift()
            .finally(() => this.onToggleOpen())
    }

    onNameChange(e){
        this.setState({name: e.target.value})
    }

    onNumberChange(e){
        const re = /^\d*(,\d{0,2})?$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({giftAmount: e.target.value})
        }
    }


    render() {
        return (
            <div className="gift">
                <div className="gift-image">
                    <div className="gift-index-triangle"/>
                    <div className="gift-index">
                        {this.props.idx}
                    </div>
                    <RatioImage
                        ratioHeight={9}
                        ratioWidth={16}
                        // src={`data:image/png;base64, ${this.props.imageB64}`}
                        src={this.props.imageUrl}
                        width={"100%"}
                    />
                </div>
                <div className="gift-header">
                    <h2 className="gift-title">{this.props.title}</h2>
                    <h2 className="gift-price">€ {this.props.price}</h2>
                </div>
                <p className="gift-description">
                    {this.props.description}
                </p>
                <p className="gift-info">
                    {this.props.totalNumberGiven > 0 ? <>
                        Dit cadeau is al {this.props.totalNumberGiven} keer gegeven voor een totaal bedrag van {this.props.totalGiven} euro.
                    </> : <>
                        Dit cadeau is nog niet gegeven!
                    </>}
                </p>
                <div onClick={this.onToggleOpen} className="gift-button">
                    {this.state.open ? "Sluiten" : "Dit geef ik cadeau!"}
                </div>
                {this.state.open && <div className="gift-form">
                    <div className="gift-form-body">
                        <div className="gift-form-labels">
                            <div className="gift-row">
                                <label>Naam</label>
                            </div>
                            <div className="gift-row">
                                <label>Bedrag</label>
                            </div>
                        </div>
                        <div className="gift-form-inputs">
                            <div className="gift-row">
                                <input
                                    value={this.state.name}
                                    onChange={this.onNameChange}
                                    type="text"
                                    placeholder="Naam"
                                />
                            </div>
                            <div className="gift-row">
                                <input
                                    type="text"
                                    placeholder="Bedrag"
                                    value={this.state.giftAmount}
                                    onChange={this.onNumberChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="gift-form-footer">
                        <div onClick={this.onSend} className="gift-button">
                            Verstuur
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

Gift.propTypes = {
    uuid: PropTypes.string,
    idx: PropTypes.number,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    totalGiven: PropTypes.number,
    totalNumberGiven: PropTypes.number,
    description: PropTypes.string,
    onSend: PropTypes.func.isRequired
}

export default Gift;
