import React, {Component} from 'react';
import Screen from "../../components/screen/Screen";

import "./Couple.css"

import Band from "../../images/band.png"
import Food from "../../images/food.png"
import Snackbar from "../../images/snackbar.png"
import Kusjes from "../../images/kusjes.png"
import Haar from "../../images/haar.png"
import Poesjes from "../../images/poesjes.png"
import Contract from "../../images/contract.png"
import Partner from "../../images/partner.png"
import Ring from "../../images/ring.png"

import InnerScreen from "../../components/screen/InnerScreen";
import ScreenTitle from "../../components/screenTitle/ScreenTitle";
import Footer from "../../components/screen/Footer";

class Couple extends Component {
    render() {
        return (
            <Screen className="couple">
                <InnerScreen>
                    <ScreenTitle>
                        <h1>Jimme & Santoshi</h1>
                        <h3>Hun verhaal</h3>
                    </ScreenTitle>
                    <div>
                        <h2>5 Oktober 2010</h2>
                        <p>
                            Bij de eerste repetitie van de lustrum-band van
                            hun studentenvereniging IBN Batutta
                            ontmoeten ze elkaar. Waar Santoshi de sterren
                            van de hemel zong, was Jimme de sexy basist.
                            Liefde op het eerste gezicht zou je zeggen,
                            maar niets was minder waar.
                        </p>
                        <img className="story-image" width="100%" alt="Band" src={Band}/>
                        <h2>September 2011</h2>
                        <p>
                            De lustrumactiviteiten zijn afgelopen, de zomer
                            is voorbij. En zo is Jimmes vorige liefde. Jimme
                            komt eens vaker bij Santoshi eten en laat zijn
                            kookkunsten zien. Pasta met varkenshaas en
                            courgette saus. Dat smaakt naar meer.
                        </p>
                        <img className="story-image" width="100%" alt="Band" src={Food}/>
                        <h2>31 Oktober 2011</h2>
                        <p>
                            Jimme's feestjes zijn berucht om Jimme's
                            Snackbar. Zo ook tijdens het eerste feestje dat
                            Santoshi mocht bijwonen. Jimme kreeg die
                            avond vaak de vraag: wat zijn jullie nou van
                            elkaar? En zo geschiedde het, vanaf deze dag
                            gingen ze officieel als vriendje en vriendinnetje
                            door het leven.
                        </p>
                        <img className="story-image" width="100%" alt="Snackbar" src={Snackbar}/>
                        <h2>NOVEMBER 2013 - AUGUSTUS 2016</h2>
                        <p>
                            Van november 2013 tot augustus 2016 hebben zij een LAT-relatie.
                            Kusjes via Whatsapp en foto’s van het eten.
                            Santoshi in Nepal en later Santoshi in Groningen om haar studie af te maken.
                            Jimme in Den Haag bij zijn eerste baan als Planoloog bij Witteveen+Bos.
                        </p>
                        <img className="story-image" width="100%" alt="Kusjes" src={Kusjes}/>
                        <h2>SEPTEMBER 2016</h2>
                        <p>
                            Het LATten eindigt wanneer Santoshi afstudeert en werk vindt in Snits bij de gemeente Súdwest-Fryslân.
                            Ze gaan samenwonen in Groningen.
                            September 2016 trekt Jimme bij Santoshi in aan de Zaagmuldersweg 540.
                        </p>
                        <p>
                            N.B. het meeste baardhaar dat Jimme ooit heeft gehad is te zien op deze foto :’)
                        </p>
                        <img className="story-image" width="100%" alt="Haar" src={Haar}/>
                        <h2>31 OKTOBER 2017</h2>
                        <p>
                            Op de terugreis vanuit Kroatië heeft Santoshi een verrassing voor Jimme.
                            Een vervroegd verjaardagscadeautje.
                            Vanaf Schiphol rijden ze langs twee adresjes.
                            Thuisgekomen hebben ze twee huisgenoten er bij: Tamatoa en Te’Fiti.
                        </p>
                        <img className="story-image" width="100%" alt="Poesjes" src={Poesjes}/>
                        <h2>31 OKTOBER 2017</h2>
                        <p>
                            Tijdens het wandelen van het Pieterpad struikelt Santoshi over een woning op Funda.
                            Dit moeten ze kopen.
                            Kijken, bieden en ondertekenen; twee weken later is het alweer geregeld.
                            Ze kopen samen Winschoterweg 12 te Groningen.
                        </p>
                        <img className="story-image" width="100%" alt="Contract" src={Contract}/>
                        <h2>31 OKTOBER 2019</h2>
                        <p>
                            8  jaar samen - Een speciale dag. Met Leander, Lars, Hjalmar en Frederike als getuigen worden ze elkaars geregistreerd partner.
                            Met een bescheiden feestje met een heerlijke borrel met de ouders erbij wordt het gevierd.
                        </p>
                        <img className="story-image" width="100%" alt="Partner" src={Partner}/>
                        <h2>24 AUGUSTUS 2020</h2>
                        <p>
                            Het aanzoek.
                            Jimme wilde Santoshi altijd al op een berg ten huwelijk vragen.
                            Maar ja, Corona. Dus het werd de Koningsbelt.
                            75,5 meter boven zeeniveau op het hoogste punt nabij de Holterberg vraagt hij haar (in de stromende regen) ten huwelijk.
                            Zij zegt: ‘ja’
                        </p>
                        <img className="story-image" width="100%" alt="Ring" src={Ring}/>
                        <h2>10 JULI 2021</h2>
                        <p>
                            Het volgende hoofdstuk van dit verhaal beleven we samen!
                            Kom met ons het feest vieren op 10 juli!
                        </p>
                    </div>
                </InnerScreen>
                <Footer/>
            </Screen>
        )
    }
}

export default Couple
