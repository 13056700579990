import React, {Component} from 'react';

import './RemoveButton.css'

class RemoveButton extends Component {

    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this)
    }

    onClick(){
        this.props.onClick(this.props.id)
    }


    render() {
        return <div className="remove-button-wrapper" onClick={this.onClick}>
            <div className="remove-button"/>
        </div>
    }
}

RemoveButton.defaultProps = {
    onClick: () => {},
}

export default RemoveButton;
