import React, {Component} from 'react';
import Screen from "../../components/screen/Screen";

import "./Gifts.css"
import ScreenTitle from "../../components/screenTitle/ScreenTitle";
import InnerScreen from "../../components/screen/InnerScreen";
import Gift from "../../components/gift/Gift";
import Footer from "../../components/screen/Footer";
import {API_URL} from "../../Constants";

class Gifts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gifts: []
        }

        this.updatePresents = this.updatePresents.bind(this)
    }

    componentDidMount() {
        console.log(API_URL)
        this.updatePresents()
    }

    updatePresents(){
        fetch(`${API_URL}/presents`)
            .then(response => response.json())
            .then(gifts => this.setState({gifts}))
    }

    render() {
        return (
            <Screen className="gifts">
                <InnerScreen>
                    <ScreenTitle>
                        <h1>Cadeaus</h1>
                    </ScreenTitle>
                    <div>
                        <p>
                            Jimme en Santoshi willen voor hun huwelijksreis
                            graag een reis maken naar Nepal. Hieronder
                            vind je de huwelijkreis in 'kleine stukjes'.
                            Vind je het leuk om ook een stukje huwelijkreis te
                            geven? Klik dan op 'Dit geef ik cadeau!'.
                            Het bedrag mag je contant geven.
                            Overmaken mag natuurlijk ook.
                            Hiervoor ontvang je twee weken voor de bruiloft een e-mail met meer informatie.
                        </p>
                        <div className="gift-list">
                            {this.state.gifts.map(gift => <Gift
                                key={gift.uuid}
                                idx={gift.index}
                                uuid={gift.uuid}
                                title={gift.name}
                                price={gift.price.replace(".", ",")}
                                description={gift.description}
                                imageUrl={gift.imageUrl}
                                totalGiven={gift.totalGiven.replace(".", ",")}
                                totalNumberGiven={gift.totalNumberGiven}
                                onSend={this.updatePresents}
                                />)}
                        </div>
                    </div>
                </InnerScreen>
                <Footer/>
            </Screen>
        )
    }
}

export default Gifts
