import Contact from "./screens/contact/Contact";
import Gifts from "./screens/gifts/Gifts";
import Program from "./screens/program/Program";
import Couple from "./screens/couple/Couple";
import Rsvp from "./screens/rsvp/Rsvp";
import Form from "./screens/form/Form";

const routes = [
    { path: "/", Screen: Rsvp},
    { path: "/jimme-en-santoshi", Screen: Couple},
    { path: "/de-grote-dag", Screen: Program},
    { path: "/cadeaus", Screen: Gifts},
    { path: "/rsvp", Screen: Form},
    { path: "/contact", Screen: Contact}
]

export default routes
