import React, {Component} from 'react';
import "./ProgramItem.css"


class ProgramItem extends Component {
    render() {
        return (
            <li className="program-item">
                <img className="program-item-image" src={this.props.imageSrc} alt={this.props.imageAlt}/>
                <div>
                    <h2 className="program-item-time">{this.props.time}</h2>
                    <p className="program-item-description">{this.props.description}</p>
                </div>
            </li>
        );
    }
}

export default ProgramItem;