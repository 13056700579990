import React, {Component} from 'react';
import Hamburger from "../../images/square.png"

import "./Nav.css"
import NavItem from "./NavItem";
import NavLink from "./NavLink";

class Nav extends Component {


    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
        this.toggleIsOpen = this.toggleIsOpen.bind(this)
        this.closeNav = this.closeNav.bind(this)
    }

    toggleIsOpen() {
        this.toggleState("isOpen")
    }

    closeNav() {
        this.setState({"isOpen": false})
    }

    toggleState(key) {
        this.setState(state => ({
            [key]: !state[key]
        }))
    }

    render() {
        return (<>
            <div className="js-nav">
                <div className="js-nav-hamburger" onClick={this.toggleIsOpen}>
                    <img src={Hamburger} alt={"Menu"} height="50px"/>
                </div>
                <div className={this.state.isOpen ? "js-nav-open" : "js-nav-closed"}>
                    <nav>
                        <ul>
                            <NavItem>
                                <NavLink onClick={this.closeNav} to="/">RSVP</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.closeNav} to="/jimme-en-santoshi">J&S</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.closeNav} to="/de-grote-dag">De grote dag</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.closeNav} to="/cadeaus">Cadeaus</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.closeNav} to="/contact">Contact</NavLink>
                            </NavItem>
                        </ul>
                    </nav>
                </div>
            </div>
        </>)
    }
}

export default Nav;