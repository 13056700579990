import React, {Component} from 'react';
import {withRouter, Link} from "react-router-dom";

import "./NavLink.css"

class NavLink extends Component {

    isActive(){
        return [this.props.to]
            .concat(this.props.otherRoutes)
            .includes(this.props.location.pathname)
    }

    render() {
        const active = this.isActive()? "js-nav-link-active": ""
        return (
            <Link onClick={this.props.onClick} to={this.props.to} className={`js-nav-link ${active}`}>
                {this.props.children}
            </Link>
        );
    }
}

export default withRouter(NavLink);