import React, {Component} from 'react';
import {Route, withRouter} from "react-router-dom";
import {CSSTransition} from 'react-transition-group'

import "./Fade.css"
import routes from "../../routes";

const pathMap = {
    "/jimme-en-santoshi": 0,
    "/de-grote-dag": 1,
    "/": 2,
    "/cadeaus": 3,
    "/contact": 4
}


class Content extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <>
            {routes.map(({path, Screen}) =>
                <Route key={path} exact path={path}>
                    {({match}) => {
                        return <CSSTransition
                            in={match != null}
                            timeout={{
                                exit:0,
                                enter: 500
                            }}
                            classNames="fade"
                            unmountOnExit
                            onExited={() => window.scroll(0,0)}
                        >
                            <Screen/>
                        </CSSTransition>
                    }}
                </Route>
            )}
        </>
    }
}

export default withRouter(Content)
