import React, {Component} from 'react';
import Screen from "../../components/screen/Screen";

import "./Contact.css"
import InnerScreen from "../../components/screen/InnerScreen";
import ScreenTitle from "../../components/screenTitle/ScreenTitle";
import Eelke from "../../images/contact/eelke.png"
import Footer from "../../components/screen/Footer";

class Contact extends Component {
    render() {
        return (
            <Screen className="contact">
                <InnerScreen>
                    <ScreenTitle>
                        <h1>Contact</h1>
                    </ScreenTitle>
                    <div>
                        <p>
                            Heb je vragen? Wil je een speech houden, een
                            liedje zingen of heb je een verzoeknummer
                            voor op de dansvloer? Neem dan contact op
                            met Ceremoniemeester Eelke de Jong
                            door een mailtje te sturen naar:
                            jimmesantoshi@gmail.com
                        </p>
                        <img className="eelke-image" width="100%" alt="Eelke" src={Eelke}/>
                        <h5 className="eelke-header">Eelke de Jong</h5>
                    </div>
                </InnerScreen>
                <Footer/>
            </Screen>
        );
    }
}

export default Contact;