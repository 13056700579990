import React, {Component} from 'react';
import Flower from '../../images/flower50.png';
import Screen from "../../components/screen/Screen";
import InnerScreen from "../../components/screen/InnerScreen";
import ScreenTitle from "../../components/screenTitle/ScreenTitle";
import "./Program.css"

import Heart from "../../images/program/heart.png"
import Rings from "../../images/program/rings.png"
import Glasses from "../../images/program/glasses.png"
import Cutlery from "../../images/program/cutlery.png"
import Dance from "../../images/program/dance.png"
import Bed from "../../images/program/bed.png"

import ProgramItem from "../../components/programItem/ProgramItem";
import Footer from "../../components/screen/Footer";
import {Link} from "react-router-dom";

class Program extends Component {

    constructor(props) {
        super(props);
        this.program = React.createRef()
        this.flower = React.createRef()

        this.state = {
            flower: null
        }

        this.scroll = this.scroll.bind(this)
        this.resize = this.resize.bind(this)
    }

    scroll(){
        window.scroll({
            top: this.program.current.offsetTop - 100,
            behavior: 'smooth'
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize)
        window.setTimeout(this.resize, 100) //wait for page to be loaded properly
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize)
    }

    resize(){
        this.setState({
            flower: {
                backgroundImage: `url(${Flower})`,
                backgroundPositionY: this.flower.current.offsetTop,
            }
        })
    }

    render() {
        return (
            <Screen className="program" style={this.state.flower}>
                <InnerScreen>
                    <ScreenTitle>
                        <h1>De grote dag</h1>
                        <h3>10.07.2021</h3>
                    </ScreenTitle>
                    <div>
                        <p>
                            Vanaf 16.00 uur ben je van harte welkom aan
                            de Winschoterweg 12.
                             <span className="scroll" onClick={this.scroll}>Scroll</span>
                             naar beneden voor het programma.
                        </p>

                        <h2 className="sub-title">Dresscode</h2>
                        <p>
                            Jimme en Santoshi vinden het leuk als je
                            feestelijk gekleed komt.
                        </p>
                            <h2 className="sub-title" ref={this.flower}>Blijf overnachten in je eigen tent!</h2>
                            <p>
                                Wil je na afloop blijven slapen? Zorg voor een
                                eigen tentje. Deze mag je tussen 12.00 en
                                15.30 komen opzetten. De volgende ochtend
                                genieten we nog even samen na
                                tijdens het ontbijt. Laat via onderstaand
                                formulier weten of je ook blijft slapen.
                            </p>
                            <div className="rsvp-wrapper">
                                <Link to={"/rsvp"} className="rsvp-button no-select">
                                    Laat ons weten of je erbij bent!
                                </Link>
                            </div>
                            <ScreenTitle  className="program-header">
                                <h1 ref={this.program}>Programma</h1>
                            </ScreenTitle>
                            <div className="program-list">
                                <ul>
                                    <ProgramItem
                                        imageSrc={Heart}
                                        imageAlt={"Hart"}
                                        time={"16.00"}
                                        description={"Aanvang"}
                                    />
                                    <ProgramItem
                                        imageSrc={Rings}
                                        imageAlt={"Ringen"}
                                        time={"16.30"}
                                        description={"Ceremonie"}
                                    />
                                    <ProgramItem
                                        imageSrc={Glasses}
                                        imageAlt={"Glazen"}
                                        time={"17.00"}
                                        description={"Proost!"}
                                    />
                                    <ProgramItem
                                        imageSrc={Cutlery}
                                        imageAlt={"Eten"}
                                        time={"19.00"}
                                        description={"Diner"}
                                    />
                                    <ProgramItem
                                        imageSrc={Dance}
                                        imageAlt={"Dansen"}
                                        time={"21.00"}
                                        description={"Feest!"}
                                    />
                                    <ProgramItem
                                        imageSrc={Bed}
                                        imageAlt={"Slapen"}
                                        time={"01.00"}
                                        description={"Bedtijd?"}
                                    />
                                </ul>
                            </div>
                        </div>
                </InnerScreen>
                <Footer/>
            </Screen>
        )
    }
}

export default Program
