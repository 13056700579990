import React, {Component} from 'react';
import GuestList from "../guestList/GuestList";
import {FormContext} from "../../context/FormContext";

import "./RSVPForm.css"
import Spinner from "../spinner/Spinner";
import {API_URL} from "../../Constants";

class RsvpForm extends Component {

    static contextType = FormContext

    constructor(props) {
        super(props)

        this.state = {
            formSent: false,
            emailError: false,
            guestError: false
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    promiseState(state){
        return new Promise(resolve => {
            this.setState(state, () => resolve(state))
        })
    }

    validateEmail(email) {
        if(!email) return false
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateGuests() {
        return this.context.guests.some((guest) => guest.name.length > 0)
    }

    validateForm(){
        const isEmail = this.validateEmail(this.context.email)
        const hasGuest = this.validateGuests()
        this.setState({
            emailError: !isEmail,
            guestError: !hasGuest
        })
        return isEmail && hasGuest
    }

    postForm() {
        const data = JSON.stringify({
            email: this.context.email,
            present: this.context.present,
            guests: this.context.guests
                .filter(guest => guest.name.length > 0)
                .map(guest => ({
                    name: guest.name,
                    sleepOver: guest.sleepOver,
                    diet: guest.diet
                })),
            comments: this.context.comments
        })

        return fetch(`${API_URL}/attendance`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        })
            .then((response) => {if(!response.ok){throw new Error(response.status)}})
            .then(() => this.setState({formSent: true}))
    }



    onSubmit(){
        if(this.validateForm()){
            return this.promiseState({processing: true})
                .then(() => this.postForm())
                .then(() => this.promiseState({
                    formSent: this.context.present ? "Leuk dat jullie komen!" : "Bedankt voor het laten weten!",
                }))
                .catch(() => this.promiseState({
                    formSent: "Er is iets mis gegaan, probeer het later nog eens."
                }))
                .finally(() => this.promiseState({processing: false}))
        }
        window.scrollTo(0, 0)
    }

    render() {
        if(this.state.processing) return <Spinner/>
        if(this.state.formSent) return <p>{this.state.formSent}</p>
        return (
            <div className="form">
                <hr/>
                {this.state.emailError && <div className="form-row">
                    <div className="form-error">Geef een email-adres</div>
                </div>}
                <div className="form-row">
                     <label>E-mail*</label>
                    <div className="form-input">
                        <input
                            value={this.context.email}
                            onChange={e => this.context.setEmail(e.target.value)}
                            className="guest-email"
                            placeholder="Email"
                            type="email"
                        />
                    </div>
                </div>
                <div className="form-row stay">
                    <label>Aanwezig*</label>
                    <div className="form-input">
                        <select
                            className="guest-present"
                            value={this.context.present}
                            onChange={e => this.context.setPresent(e.target.value === "true")}
                        >
                            <option selected value="true">Aanwezig</option>
                            <option value="false">Afwezig</option>
                        </select>
                    </div>
                </div>
                {this.state.guestError && <div className="form-row">
                    <div className="form-error">Geef minimaal 1 gast op</div>
                </div>}
                <div className="form-row col">
                    <label>Gasten*</label>
                    <div className="form-input">
                        <GuestList listSize={3}/>
                    </div>
                </div>
                <div className="form-row">
                    <label>Opmerkingen</label>
                    <div className="form-input">
                        <textarea
                            placeholder="Opmerkingen"
                            value={this.context.comments}
                            onChange={e => this.context.setComments(e.target.value)}
                            className="guest-remarks"
                        />
                    </div>
                </div>
                <hr/>
                <div className="form-row">
                    <div className="submit-button" onClick={this.onSubmit}>
                        Verstuur
                    </div>
                </div>
            </div>
        )
    }
}

export default RsvpForm
