import React, {Component} from 'react';

export const FormContext = React.createContext(null)

class FormContextProvider extends Component {

    constructor(props) {
        super(props);
        this.defaultState = {
            reset: () => this.setState(this.defaultState),
            email: "",
            setEmail: email => this.setState({email}),
            emailError: null,
            setEmailError: emailError => this.setState({emailError}),
            present: true,
            setPresent: present => this.setState({present}),
            guests: [{name:"", sleepOver: false, diet: "", error: null}],
            addGuest: guest => this.setState(({guests}) => ({guests: [
                    ...guests,
                    guest
                ]})),
            removeGuest: index => this.setState(({guests}) => ({
                guests : guests.filter( (_, i ) => i !== index)
            })),
            updateGuest: (index, guest) => this.setState(({guests}) => {
                guests[index] = {...guests[index], ...guest}
                return guests
            }),
            comments: "",
            setComments: comments => this.setState({comments}),
        }
        this.state = this.defaultState
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.guests.length === 0){
            this.setState({guests: [{
                name:"",
                sleepOver: false,
                diet: "",
                error:null
            }]})
        }
    }


    render() {
        return <FormContext.Provider value={this.state}>
            {this.props.children}
        </FormContext.Provider>
    }
}

export default FormContextProvider;
