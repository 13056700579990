import React, {Component} from 'react';
import PropTypes from 'prop-types'

class RatioImage extends Component {

    getHeight() {
        return (this.props.ratioHeight / this.props.ratioWidth * 100).toFixed(2)
    }

    getStyle() {
        return {
            paddingTop: `${this.getHeight()}%`,
            backgroundImage: `url("${this.props.src}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            overflow: "hidden",
            height: 0,
            ...(this.props.width ? {width: this.props.width} : {})
        }
    }

    render() {
        return (
            <div
                className={this.props.className}
                id={this.props.id}
                style={this.getStyle()}
            />
        );
    }
}

RatioImage.defaultProps = {
    ratioWidth: 1,
    ratioHeight: 1,
}


RatioImage.propTypes = {
    className: PropTypes.string,
    ratioWidth: PropTypes.number.isRequired,
    ratioHeight: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    id: PropTypes.string,
    width: PropTypes.string
}


export default RatioImage;