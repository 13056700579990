import React, {Component} from 'react';
import PropTypes from "prop-types";
import GuestItem from "./GuestItem";

import "./GuestList.css"
import {FormContext} from "../../context/FormContext";

class GuestList extends Component {

    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        this.addGuest = this.addGuest.bind(this)
        this.removeGuest = this.removeGuest.bind(this)

    }

    addGuest(){
        this.context.addGuest({name:"", sleepOver: false, diet: "", error: null})
    }

    removeGuest(index){
        this.context.removeGuest(index)
    }


    render() {
        return (
            <div className="guest-list-wrapper">
                <div className="guest-list">
                    <hr/>
                    {this.context.guests.map((_, index) => <GuestItem
                        key={index}
                        index={index}
                        onRemove={this.removeGuest}
                    />)}
                    <hr/>
                </div>
                <div
                    onClick={this.addGuest}
                    className="add-button"
                >
                    Voeg gast toe
                </div>
            </div>
        )
    }
}

GuestList.defaultProps = {
    listSize: 1
}


GuestList.propsTypes = {
    listSize: PropTypes.number
}

export default GuestList;
