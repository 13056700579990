import React, {Component} from 'react';
import RemoveButton from "../removeButton/RemoveButton";

import "./GuestItem.css"
import PropTypes from "prop-types";
import {FormContext} from "../../context/FormContext";

class GuestItem extends Component {

    static contextType = FormContext

    constructor(props) {
        super(props);

        this.onRemove = this.onRemove.bind(this)
        this.updateName = this.updateName.bind(this)
        this.updateSleepOver = this.updateSleepOver.bind(this)
        this.updateDiet = this.updateDiet.bind(this)
    }


    onRemove(){
        this.props.onRemove(this.props.index)
    }

    updateName(e){
        this.context.updateGuest(this.props.index, {
            name: e.target.value,
        })
    }

    updateSleepOver(e){
        this.context.updateGuest(this.props.index, {
            sleepOver: e.target.value === "true",
        })
    }

    updateDiet(e){
        this.context.updateGuest(this.props.index, {
            diet: e.target.value
        })
    }

    render() {
        return (
            <div className="guest-item">
                <div className="form">
                    <div className="form-row">
                        <label>Naam*</label>
                        <div className="form-input">
                            <input
                                value={this.context.guests[this.props.index].name}
                                onChange={this.updateName}
                                className="guest-name"
                                placeholder="Naam"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="form-row stay">
                        <label>Overnachting*</label>
                        <div className="form-input">
                            <select
                                className="guest-sleepover"
                                value={this.context.guests[this.props.index].sleepOver}
                                onChange={this.updateSleepOver}
                            >
                                <option selected value="true">Ja</option>
                                <option value="false">Nee</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <label>Dieet</label>
                        <div className="form-input">
                            <textarea
                                value={this.context.guests[this.props.index].diet}
                                onChange={this.updateDiet}
                                className="guest-diet"
                                placeholder="Dieetwensen"
                            />
                        </div>
                    </div>
                </div>

                <div className="guest-remove">
                    <RemoveButton onClick={this.onRemove}/>
                </div>
            </div>
        );
    }
}

GuestItem.defaultProps = {
    onRemove: () => {},
}

GuestItem.propTypes = {
    onRemove: PropTypes.func,
    index: PropTypes.number
}

export default GuestItem;
