import React, {Component} from 'react';
import Spacer from "../../images/spacer-inverted.png";

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <img src={Spacer} alt="Spacer"/>
            </div>
        );
    }
}

export default Footer;