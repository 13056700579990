import React, {Component} from 'react';

import "./InnerScreen.css"
import classnames from "classnames/bind";

class InnerScreen extends Component {
    render() {
        return (
            <div className={classnames("inner-screen",this.props.className)}>
                <div className="inner-screen-wrapper">
                    {this.props.children}
                </div>

            </div>
        );
    }
}

export default InnerScreen;