import React, {Component} from 'react';
import InnerScreen from "../../components/screen/InnerScreen";
import ScreenTitle from "../../components/screenTitle/ScreenTitle";
import Screen from "../../components/screen/Screen";
import "./Rsvp.css"
import {Link} from "react-router-dom";

class Rsvp extends Component {

    render() {
        return (
            <Screen className="rsvp">
                <div className="background-wrapper">
                    <div className="inner-background-wrapper">
                        <InnerScreen>
                            <ScreenTitle>
                                <h1>Jimme & Santoshi</h1>
                                <h3>Gaan trouwen op 10.07.2021</h3>
                                <Link to={"/rsvp"} className="rsvp-button no-select">
                                    Laat ons weten of je erbij bent!
                                </Link>
                            </ScreenTitle>
                        </InnerScreen>
                    </div>
                </div>
            </Screen>
        )
    }
}

export default Rsvp
