import React, {Component} from 'react';
import Spacer from '../../images/spacer-inverted.png'

import "./Screen.css"

class Screen extends Component {

    render() {
        return (
            <div className="screen-wrapper">
                <div
                    className={"screen" + ` ${this.props.className}`}
                    style={this.props.style}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Screen;
